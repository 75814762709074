<template>
    <div class="mine-auth">
        <div class="main" v-if="firstLoad">
            <div class="auth-item bg-fff" @click="goThreeAuth">
                <div class="item-top flex">
                    <img src="@/assets/yq/auth-icon-01.png" class="icon" />
                    <span class="title">三要素认证</span>
                    <img
                        src="@/assets/yq/auth-icon-true.png"
                        class="status-img"
                        v-if="authInfo.threeCheck"
                    />
                    <img
                        src="@/assets/yq/auth-icon-false.png"
                        class="status-img"
                        v-else
                    />
                    <span class="status-text" v-if="authInfo.threeCheck"
                        >已认证</span
                    >
                    <span class="status-text" v-else>未认证</span>
                </div>
                <div class="item-bottom">
                    <div>手机号实名信息与身份证信息一致为同一人</div>
                </div>
                <div class="arrow" v-if="!authInfo.threeCheck">
                    <img src="@/assets/yq/mine-arrow.png" />
                </div>
            </div>

            <div class="auth-item bg-fff" @click="goOcAuth">
                <div class="item-top flex">
                    <img src="@/assets/yq/auth-icon-02.png" />
                    <span class="title">人脸识别</span>
                    <img
                        src="@/assets/yq/auth-icon-true.png"
                        class="status-img"
                        v-if="authInfo.videoCheck"
                    />
                    <img
                        src="@/assets/yq/auth-icon-false.png"
                        class="status-img"
                        v-else
                    />
                    <span class="status-text" v-if="authInfo.videoCheck"
                        >已认证</span
                    >
                    <span class="status-text" v-else>未认证</span>
                </div>
                <div class="item-bottom">
                    <div>人脸与身份证照片为同一人</div>
                </div>
                <div class="arrow" v-if="!authInfo.videoCheck">
                    <img src="@/assets/yq/mine-arrow.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getAuthInfo } from "@/utils/auth";
import { getCheckStatus } from "@/api/user";
import { getCheckPlatform,setCheckPlatform } from "@/utils/cache"
export default {
    data() {
        return {
            title: "身份认证",
            backVisible: true,
            authInfo: {},
            firstLoad: false,
            checkPlatform: ""
        };
    },
    methods: {
        goThreeAuth() {
            if(!this.authInfo.threeCheck){
                this.$router.push({
                    path: "/threeAuth",
                    query:{
                        time:new Date().getTime()
                    }
                })
            }
        },
        goOcAuth() {
            if(!this.authInfo.videoCheck){
                this.$router.push({
                    path: "/ocrIdAuth"
                })
            }
        },
    },
    created(){
        getCheckStatus().then((res) => {
            let { status, data } = res;
            if (status) {
                this.authInfo = {
                    isCheck: data.checkPass,
                }
                this.checkPlatform = data.checkPlatform;
                setCheckPlatform(data.checkPlatform);
                if( this.checkPlatform == "E_SIGN" ){
                    this.authInfo.threeCheck = data.esignThreeCheckStatus;
                    this.authInfo.videoCheck = data.esignVideoCheckStatus;
                }else{
                    this.authInfo.threeCheck = data.bestSignThreeCheckStatus;
                    this.authInfo.videoCheck = data.bestSignVideoCheckStatus;
                }
                this.firstLoad = true;
            }
        });
        
    },
};
</script>

<style scoped>
div {
    box-sizing: border-box;
}
.mine-auth {
    padding-top: 50px;
}
.mine-auth .main {
    padding: 10px 14.5px 0 14.5px;
}
.auth-item {
    width: 100%;
    height: 98px;
    border-radius: 6px;
    padding: 14.5px 15.5px 0 12px;
    position: relative;
    margin-bottom: 10px;
}
.auth-item .item-top {
    height: 33px;
    align-items: center;
}
.auth-item .item-top img {
    width: 49px;
    height: 33px;
}
.auth-item .item-top .title {
    margin-left: 6px;
}
.mine-auth .auth-item .item-top {
    height: 33px;
    align-items: center;
}
.auth-item .item-top .icon {
    width: 49px;
    height: 33px;
}
.auth-item .item-top .status-img {
    width: 11px;
    height: 13px;
    margin-left: 6.5px;
}
.auth-item .item-top > div {
}
.auth-item .item-top .title {
    margin-left: 6px;
}
.auth-item .item-top .status-text {
    margin-left: 5px;
    font-size: 10px;
    color: #ed825c;
}
.auth-item .item-bottom {
    margin-top: 12px;
}
.auth-item .item-bottom > div {
    color: #999999;
    font-size: 14px;
}
.auth-item .arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -12px;
}
.auth-item .arrow > img {
    height: 12px;
    width: 8px;
}
</style>
